import React from "react";

import pioneer from "../assets/images/pioneer.png";
import access from "../assets/images/access.png";
import iconwave from "../assets/images/icon-wave.png";

const Partners = () => {
  return (
    <>
      <section class="text-gray-700 body-font shadow-inner rounded-3xl m-6 lg:m-24 bg-purple-100">
        <div class="container px-5 pt-20 pb-6 mx-auto">
          <div class="flex flex-col text-center w-full mb-12">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
              Our Technical Partners
            </h1>
          </div>
          <div class="flex flex-wrap -m-0">
            <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
              <div class="h-full flex bg-white items-center shadow-lg border-gray-200 border p-4 rounded-lg">
                <img
                  alt="team"
                  class="w-full h-32 object-contain object-center flex-shrink-0 mr-4"
                  src={pioneer}
                />
              </div>
            </div>
            <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
              <div class="h-full flex bg-white items-center shadow-lg border-gray-200 border p-4 rounded-lg">
                <img
                  alt="team"
                  class="w-full h-32 object-contain object-center flex-shrink-0 mr-4"
                  src={access}
                />
              </div>
            </div>
            <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
              <div class="h-full flex bg-white items-center shadow-lg p-4 border-gray-200 border rounded-lg">
                <img
                  alt="team"
                  class="w-full h-32 object-contain object-center flex-shrink-0 mr-4"
                  src={iconwave}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="bg-purple-100 h-24 shadow-inner"> </div> */}
    </>
  );
};

export default Partners;
