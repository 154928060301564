import React from "react";

const Contact = () => {
  return (
    <section class="text-gray-700 body-font relative">
      <div class="container px-5 py-24 mx-auto flex sm:flex-no-wrap flex-wrap">
        <div class="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
          <iframe
            width="100%"
            height="100%"
            class="absolute inset-0"
            frameborder="0"
            title="map"
            marginheight="0"
            marginwidth="0"
            scrolling="no"
            src="https://maps.google.com/maps?width=100%&height=600&hl=en&q=JABBARCOMMUNICATIONS&ie=UTF8&t=&z=18&iwloc=B&output=embed"
          ></iframe>
          <div class="invisible bg-white relative flex flex-wrap py-6 lg:mr-4">
            <div class="lg:w-1/2 px-6">
              <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm">
                ADDRESS
              </h2>
              <p class="leading-relaxed">
                11-5, Pothireddypally X Road, Sangareddy, Telangana 502295
              </p>
            </div>
            <div class="lg:w-1/2 px-6 mt-4 lg:mt-0">
              <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm">
                EMAIL
              </h2>
              <p class="text-purple-500 leading-relaxed">
                jnetworks.srd@gmail.com jnetworksbroadband@gmail.com
              </p>
              <h2 class="title-font font-medium text-gray-900 tracking-widest text-sm mt-4">
                PHONE
              </h2>
              <p class="leading-relaxed">98495 32155</p>
            </div>
          </div>
        </div>
        <div class="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
          <h2 class="text-gray-900 text-lg mb-5 font-medium title-font uppercase">
            Contact Us
          </h2>
          <div className="flex mb-6 justify-start">
            <div className="w-16 h-1 rounded-full bg-indigo-500 inline-flex"></div>
          </div>
          <p class="leading-relaxed font-semibold mb-3 text-gray-900">
            Address :
          </p>
          <p class="leading-relaxed mb-2 text-gray-600">
            11-5, Pothireddypally X Road
          </p>
          <p class="leading-relaxed mb-2 text-gray-600">Sangareddy</p>
          <p class="leading-relaxed mb-5 text-gray-600">Telangana 502295</p>
          <p class="leading-relaxed font-semibold mb-2 text-gray-900">
            Email :
          </p>
          <p class="leading-relaxed mb-5 text-gray-600">
            jnetworks.srd@gmail.com
          </p>
          <p class="leading-relaxed font-semibold mb-2 text-gray-900">
            Phone :
          </p>
          <p class="leading-relaxed mb-10 text-gray-600">98495 32155</p>

          <h4 class="text-gray-900 text-md font-medium title-font">
            If you have any questions or concerns, please feel free to reach out
            to us.
          </h4>
        </div>
      </div>
    </section>
  );
};

export default Contact;
