import React from "react";

const Plans = () => {
  const [openTab, setOpenTab] = React.useState(1);
  return (
    <>
      <section className="text-gray-700 body-font">
        <div className="container px-2 md:px-6 py-12 mx-auto">
          <div className="flex flex-col text-center w-full mb-20">
            <h1 className="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900">
              Pricing
            </h1>
            <p className="lg:w-3/4 mx-auto my-4 leading-relaxed lg:leading-loose text-md lg:text-xl">
              Experience the internet at the fastest speeds with our fiber optic
              broadband. Enjoy a seamless connection, perfect for connecting
              multiple devices over WiFi and LAN. With our fiber optic
              technology, you will get superfast connectivity and the best
              broadband internet speeds at all times.
            </p>
            <p className="lg:w-3/4 mx-auto my-4 leading-relaxed lg:leading-loose text-md lg:text-xl">
              With our super quick upload and download speeds, you will be able
              to enjoy the virtual world with no buffering, no-wait downloads,
              lag-free video calling and much more.
            </p>
          </div>
          {/* <div className="lg:w-2/3 w-full mx-auto overflow-auto">
            <table className="table-auto w-full text-left whitespace-no-wrap">
              <thead>
                <tr className="text-md">
                  <th className="px-4 py-4 title-font tracking-wider font-medium text-gray-900 text-sm bg-purple-300 rounded-tl rounded-bl">
                    Plan
                  </th>
                  <th className="px-4 py-4 title-font tracking-wider font-medium text-gray-900 text-sm bg-purple-300">
                    Speed
                  </th>
                  <th className="px-4 py-4 title-font tracking-wider font-medium text-gray-900 text-sm bg-purple-300">
                    Bandwidth
                  </th>
                  <th className="px-4 py-4 title-font tracking-wider font-medium text-gray-900 text-sm bg-purple-300">
                    Price
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-md">
                  <td className="px-4 py-4">JNB 400</td>
                  <td className="px-4 py-4">12 MBPS</td>
                  <td className="px-4 py-4">200 GB</td>
                  <td className="px-4 py-4 text-md text-gray-900">
                    &#x20B9; 400
                  </td>
                </tr>
                <tr className="text-md">
                  <td className="border-t-2 border-purple-200 px-4 py-4">
                    JNB 500
                  </td>
                  <td className="border-t-2 border-purple-200 px-4 py-4">
                    25 MBPS
                  </td>
                  <td className="border-t-2 border-purple-200 px-4 py-4">
                    250 GB
                  </td>
                  <td className="border-t-2 border-purple-200 px-4 py-4 text-md text-gray-900">
                    &#x20B9; 500
                  </td>
                </tr>
                <tr className="text-md">
                  <td className="border-t-2 border-purple-200 px-4 py-4">
                    JNB 650
                  </td>
                  <td className="border-t-2 border-purple-200 px-4 py-4">
                    30 MBPS
                  </td>
                  <td className="border-t-2 border-purple-200 px-4 py-4">
                    500 GB
                  </td>
                  <td className="border-t-2 border-purple-200 px-4 py-4 text-md text-gray-900">
                    &#x20B9; 650
                  </td>
                </tr>
                <tr className="text-md">
                  <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                    JNB 700
                  </td>
                  <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                    50 MBPS
                  </td>
                  <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                    500 GB
                  </td>
                  <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4 text-md text-gray-900">
                    &#x20B9; 700
                  </td>
                </tr>
                <tr className="text-md">
                  <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                    JNB 1050
                  </td>
                  <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                    60 MBPS
                  </td>
                  <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                    400 GB
                  </td>
                  <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4 text-md text-gray-900">
                    &#x20B9; 1050
                  </td>
                </tr>
                <tr className="text-md">
                  <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                    JNB 1250
                  </td>
                  <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                    80 MBPS
                  </td>
                  <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                    600 GB
                  </td>
                  <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4 text-md text-gray-900">
                    &#x20B9; 1250
                  </td>
                </tr>
              </tbody>
            </table>
          </div> */}

          {/* TEST */}
          <div className="w-full md:w-2/3 items-center justify-center mx-auto">
            <ul
              className="flex mb-0 w-2/3 mx-auto list-none flex-wrap pt-3 pb-4 flex-row"
              role="tablist"
            >
              <li className="-mb-px last:mr-0 flex-auto text-center py-4">
                <a
                  className={
                    "text-xs font-bold uppercase px-2 md:px-5 py-3 shadow-xl rounded-bl-md rounded-tl-md block leading-normal " +
                    (openTab === 1
                      ? "text-white bg-indigo-700 border-2 border-r-0 border-indigo-700"
                      : "text-indigo-700 bg-white border-2 border-r-0 border-indigo-700")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  <span className="hidden md:block">Monthly</span>
                  <span className="block md:hidden">1 mo</span>
                </a>
              </li>
              <li className="-mb-px last:mr-0 flex-auto text-center py-4">
                <a
                  className={
                    "text-xs font-bold uppercase px-2 md:px-5  py-3 shadow-xl block leading-normal " +
                    (openTab === 2
                      ? "text-white bg-indigo-700 border-2 border-indigo-700"
                      : "text-indigo-700 bg-white border-2 border-indigo-700")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(2);
                  }}
                  data-toggle="tab"
                  href="#link2"
                  role="tablist"
                >
                  <span className="hidden md:block">6 Months</span>
                  <span className="block md:hidden">6 mo.s</span>
                </a>
              </li>
              <li className="-mb-px last:mr-0 flex-auto text-center py-4">
                <a
                  className={
                    "text-xs font-bold uppercase px-2 md:px-5  py-3 shadow-xl rounded-br-md rounded-tr-md block leading-normal " +
                    (openTab === 3
                      ? "text-white bg-indigo-700 border-2 border-l-0 border-indigo-700"
                      : "text-indigo-700 bg-white border-2 border-l-0 border-indigo-700")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(3);
                  }}
                  data-toggle="tab"
                  href="#link3"
                  role="tablist"
                >
                  <span className="hidden md:block">12 Months</span>
                  <span className="block md:hidden">12 mo.s</span>
                </a>
              </li>
            </ul>
            <div className="px-4 py-5 flex-auto">
              <div className="tab-content tab-space">
                <div
                  className={
                    "overflow-auto " + (openTab === 1 ? "block" : "hidden")
                  }
                  id="link1"
                >
                  <table className="table-auto w-full text-left whitespace-no-wrap">
                    <thead>
                      <tr className="text-md">
                        <th className="px-4 py-4 title-font tracking-wider font-medium text-gray-900 text-sm bg-purple-300 rounded-tl rounded-bl">
                          Plan
                        </th>
                        <th className="px-4 py-4 title-font tracking-wider font-medium text-gray-900 text-sm bg-purple-300">
                          Speed
                        </th>
                        <th className="px-4 py-4 title-font tracking-wider font-medium text-gray-900 text-sm bg-purple-300">
                          Bandwidth
                        </th>
                        <th className="px-4 py-4 title-font tracking-wider font-medium text-gray-900 text-sm bg-purple-300">
                          Price
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="text-md">
                        <td className="px-4 py-4">JNB 400</td>
                        <td className="px-4 py-4">8 MBPS</td>
                        <td className="px-4 py-4">150 GB</td>
                        <td className="px-4 py-4 text-md text-gray-900">
                          &#x20B9; 400
                        </td>
                      </tr>
                      <tr className="text-md">
                        <td className="border-t-2 border-purple-200 px-4 py-4">
                          JNB 500
                        </td>
                        <td className="border-t-2 border-purple-200 px-4 py-4">
                          15 MBPS
                        </td>
                        <td className="border-t-2 border-purple-200 px-4 py-4">
                          200 GB
                        </td>
                        <td className="border-t-2 border-purple-200 px-4 py-4 text-md text-gray-900">
                          &#x20B9; 500
                        </td>
                      </tr>
                      <tr className="text-md">
                        <td className="border-t-2 border-purple-200 px-4 py-4">
                          JNB 650
                        </td>
                        <td className="border-t-2 border-purple-200 px-4 py-4">
                          30 MBPS
                        </td>
                        <td className="border-t-2 border-purple-200 px-4 py-4">
                          300 GB
                        </td>
                        <td className="border-t-2 border-purple-200 px-4 py-4 text-md text-gray-900">
                          &#x20B9; 650
                        </td>
                      </tr>
                      <tr className="text-md">
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                          JNB 850
                        </td>
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                          40 MBPS
                        </td>
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                          400 GB
                        </td>
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4 text-md text-gray-900">
                          &#x20B9; 850
                        </td>
                      </tr>
                      <tr className="text-md">
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                          JNB 1050
                        </td>
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                          60 MBPS
                        </td>
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                          400 GB
                        </td>
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4 text-md text-gray-900">
                          &#x20B9; 1050
                        </td>
                      </tr>
                      <tr className="text-md">
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                          JNB 1250
                        </td>
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                          80 MBPS
                        </td>
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                          600 GB
                        </td>
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4 text-md text-gray-900">
                          &#x20B9; 1250
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  className={
                    "overflow-auto " + (openTab === 2 ? "block" : "hidden")
                  }
                  id="link2"
                >
                  <table className="table-auto w-full text-left whitespace-no-wrap">
                    <thead>
                      <tr className="text-md">
                        <th className="px-4 py-4 title-font tracking-wider font-medium text-gray-900 text-sm bg-purple-300 rounded-tl rounded-bl">
                          Plan
                        </th>
                        <th className="px-4 py-4 title-font tracking-wider font-medium text-gray-900 text-sm bg-purple-300">
                          Speed
                        </th>
                        <th className="px-4 py-4 title-font tracking-wider font-medium text-gray-900 text-sm bg-purple-300">
                          Bandwidth
                        </th>
                        <th className="px-4 py-4 title-font tracking-wider font-medium text-gray-900 text-sm bg-purple-300">
                          Price
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="text-md">
                        <td className="px-4 py-4">JNB 2200</td>
                        <td className="px-4 py-4">8 MBPS</td>
                        <td className="px-4 py-4">150 GB</td>
                        <td className="px-4 py-4 text-md text-gray-900">
                          &#x20B9; 2200
                        </td>
                      </tr>
                      <tr className="text-md">
                        <td className="border-t-2 border-purple-200 px-4 py-4">
                          JNB 2750
                        </td>
                        <td className="border-t-2 border-purple-200 px-4 py-4">
                          15 MBPS
                        </td>
                        <td className="border-t-2 border-purple-200 px-4 py-4">
                          200 GB
                        </td>
                        <td className="border-t-2 border-purple-200 px-4 py-4 text-md text-gray-900">
                          &#x20B9; 2750
                        </td>
                      </tr>
                      <tr className="text-md">
                        <td className="border-t-2 border-purple-200 px-4 py-4">
                          JNB 3575
                        </td>
                        <td className="border-t-2 border-purple-200 px-4 py-4">
                          30 MBPS
                        </td>
                        <td className="border-t-2 border-purple-200 px-4 py-4">
                          300 GB
                        </td>
                        <td className="border-t-2 border-purple-200 px-4 py-4 text-md text-gray-900">
                          &#x20B9; 3575
                        </td>
                      </tr>
                      <tr className="text-md">
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                          JNB 4675
                        </td>
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                          40 MBPS
                        </td>
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                          400 GB
                        </td>
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4 text-md text-gray-900">
                          &#x20B9; 4675
                        </td>
                      </tr>
                      <tr className="text-md">
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                          JNB 5775
                        </td>
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                          60 MBPS
                        </td>
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                          400 GB
                        </td>
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4 text-md text-gray-900">
                          &#x20B9; 5775
                        </td>
                      </tr>
                      <tr className="text-md">
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                          JNB 6875
                        </td>
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                          80 MBPS
                        </td>
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                          600 GB
                        </td>
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4 text-md text-gray-900">
                          &#x20B9; 6875
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  className={
                    "overflow-auto " + (openTab === 3 ? "block" : "hidden")
                  }
                  id="link3"
                >
                  <table className="table-auto w-full text-left whitespace-no-wrap">
                    <thead>
                      <tr className="text-md">
                        <th className="px-4 py-4 title-font tracking-wider font-medium text-gray-900 text-sm bg-purple-300 rounded-tl rounded-bl">
                          Plan
                        </th>
                        <th className="px-4 py-4 title-font tracking-wider font-medium text-gray-900 text-sm bg-purple-300">
                          Speed
                        </th>
                        <th className="px-4 py-4 title-font tracking-wider font-medium text-gray-900 text-sm bg-purple-300">
                          Bandwidth
                        </th>
                        <th className="px-4 py-4 title-font tracking-wider font-medium text-gray-900 text-sm bg-purple-300">
                          Price
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="text-md">
                        <td className="px-4 py-4">JNB 4000</td>
                        <td className="px-4 py-4">8 MBPS</td>
                        <td className="px-4 py-4">150 GB</td>
                        <td className="px-4 py-4 text-md text-gray-900">
                          &#x20B9; 4000
                        </td>
                      </tr>
                      <tr className="text-md">
                        <td className="border-t-2 border-purple-200 px-4 py-4">
                          JNB 5000
                        </td>
                        <td className="border-t-2 border-purple-200 px-4 py-4">
                          15 MBPS
                        </td>
                        <td className="border-t-2 border-purple-200 px-4 py-4">
                          200 GB
                        </td>
                        <td className="border-t-2 border-purple-200 px-4 py-4 text-md text-gray-900">
                          &#x20B9; 5000
                        </td>
                      </tr>
                      <tr className="text-md">
                        <td className="border-t-2 border-purple-200 px-4 py-4">
                          JNB 6500
                        </td>
                        <td className="border-t-2 border-purple-200 px-4 py-4">
                          30 MBPS
                        </td>
                        <td className="border-t-2 border-purple-200 px-4 py-4">
                          300 GB
                        </td>
                        <td className="border-t-2 border-purple-200 px-4 py-4 text-md text-gray-900">
                          &#x20B9; 6500
                        </td>
                      </tr>
                      <tr className="text-md">
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                          JNB 8500
                        </td>
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                          40 MBPS
                        </td>
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                          400 GB
                        </td>
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4 text-md text-gray-900">
                          &#x20B9; 8500
                        </td>
                      </tr>
                      <tr className="text-md">
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                          JNB 10500
                        </td>
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                          60 MBPS
                        </td>
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                          400 GB
                        </td>
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4 text-md text-gray-900">
                          &#x20B9; 10500
                        </td>
                      </tr>
                      <tr className="text-md">
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                          JNB 12500
                        </td>
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                          80 MBPS
                        </td>
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4">
                          600 GB
                        </td>
                        <td className="border-t-2 border-b-2 border-purple-200 px-4 py-4 text-md text-gray-900">
                          &#x20B9; 12500
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="text-center w-full p-4 italic text-sm lg:text-lg text-purple-500">
                  <p>
                    FUP applicable for all plans. Conditions apply
                    <span className="text-red-700 font-bold ml-1">*</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* TEST */}
        </div>
      </section>
    </>
  );
};

export default Plans;
