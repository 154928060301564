import React from "react";

import Checked from "../assets/checked.svg";
import Love from "../assets/favourite.svg";
import Speed from "../assets/rocket.svg";
// import SpeedTest from "../assets/speed_test.svg";
import HeroVideo from "../assets/hero_video.mp4";

const Hero = () => {
  return (
    <div>
      <section className="text-gray-700 body-font">
        <div className="container mx-auto flex px-5 py-12 md:flex-row flex-col items-center">
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
            {/* <img
              className="object-cover object-center rounded"
              alt="hero"
              src={SpeedTest}
            /> */}

            <video
              className="object-cover object-center rounded"
              autoPlay="true"
              loop="true"
              muted="true"
              controls
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            >
              <source src={HeroVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {/* <div
              className="relative block h-0 p-0 overflow-hidden"
              style={{ paddingTop: "56.25%" }}
            >
              <iframe
                title="J"
                className="absolute top-0 left-0 bottom-0 w-full h-full rounded-md"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                autoplay
              ></iframe>
              
            </div> */}
          </div>
          <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col items-center lg:items-start lg:text-left">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-center lg:text-left text-purple-900">
              The speed{" "}
              <img
                src={Speed}
                alt="speed"
                className="inline-block h-6 md:h-8 w-6 md:w-8"
              />{" "}
              you need at a price you’ll love{" "}
              <img
                src={Love}
                alt="love"
                className="inline-block h-4 md:h-6 w-4 md:w-6"
              />
              <br className="hidden lg:inline-block" />
            </h1>
            <p className="mb-8 leading-relaxed text-lg m-4 md:m-0 lg:text-xl">
              Internet you can count on for all that you do.
              <ul className="flex-row">
                <li className="mt-2">
                  <img
                    src={Checked}
                    alt="checked"
                    className="inline-block h-4 w-4 mr-2"
                  />
                  Enjoy consistent speeds for your family
                </li>
                <li className="mt-2">
                  <img
                    src={Checked}
                    alt="checked"
                    className="inline-block h-4 w-4 mr-2"
                  />
                  Seamlessly stream, work, and stay connected to what matters
                </li>
                <li className="mt-2">
                  <img
                    src={Checked}
                    alt="checked"
                    className="inline-block h-4 w-4 mr-2"
                  />
                  Connect multiple devices at once – stream on your own screen
                </li>
              </ul>
            </p>
            {/* <div className="flex justify-center">
              <button className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                Button
              </button>
              <button className="ml-4 inline-flex text-gray-700 bg-gray-200 border-0 py-2 px-6 focus:outline-none hover:bg-gray-300 rounded text-lg">
                Button
              </button>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;
