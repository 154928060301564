import React from "react";
const Pricing = () => {
  return (
    <>
      <section class="text-gray-700 bg-white body-font border-purple-200 overflow-hidden">
        <div class="container px-8 py-12 md:py-24 mx-auto flex flex-wrap">
          <div class="flex flex-col text-center w-full mb-20">
            <h1 class="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900">
              Pricing
            </h1>
            <div className="md:ml-auto flex flex-wrap items-center text-base justify-center">
              <a
                className="mr-4 shadow-lg transition duration-300 ease-in-out focus:outline-none focus:shadow-outline border border-purple-700 hover:bg-purple-700 text-purple-700 hover:text-white font-semibold ml-4 md:ml-0 mt-4 md:mt-0 py-2 px-4 rounded uppercase"
                href="/pricing"
              >
                VIEW ALL PLANS
              </a>
            </div>
          </div>

          <div class="lg:w-1/4 mt-48 hidden lg:shadow-l-lg lg:block">
            <div class="mt-px border-t border-gray-400 border-b border-l rounded-tl-lg rounded-bl-lg overflow-hidden font-semibold">
              <p class="text-gray-900 h-24 text-center px-4 flex items-center justify-start">
                Download/Upload
              </p>
              <p class="bg-gray-100 text-gray-900 h-24 text-center px-4 flex items-center justify-start">
                Bandwidth
              </p>
              <p class="text-gray-900 h-24 text-center px-4 flex items-center justify-start">
                Usage
              </p>
            </div>
          </div>
          <div class="flex lg:w-3/4 w-full flex-wrap lg:border lg:shadow-lg border-gray-400 rounded-lg">
            <div class="lg:w-1/3 lg:mt-px w-full mb-10 lg:mb-0 border-2 border-gray-400 lg:border-none shadow-lg lg:shadow:none rounded-md lg:rounded-none">
              <div class="px-2 text-center h-32 lg:h-48 flex flex-col items-center justify-center">
                <h3 class="tracking-widest">START</h3>
                <h2 class="text-5xl text-gray-900 font-medium flex items-center justify-center leading-none mb-4 mt-2">
                  &#x20B9; 399
                  <span class="text-gray-600 text-base ml-1">/mo</span>
                </h2>
              </div>
              <p class="bg-gray-100 text-gray-600 h-16 lg:h-24 text-center px-2 flex items-center -mt-px justify-center border-t border-gray-400">
                12 MBPS
              </p>
              <p class="text-gray-600 text-center h-16 lg:h-24 flex items-center justify-center border-t border-gray-400">
                200 GB
              </p>
              <p class="bg-gray-100 text-gray-600 text-center h-24 p-6 flex items-center justify-center border-t rounded border-gray-400">
                Ideal for occasional video streaming, web browsing and WiFi
              </p>
            </div>
            <div class="lg:w-1/3 lg:-mt-px w-full mb-10 lg:mb-0 border-2 shadow-md lg:shadow-none rounded-md border-purple-500 relative">
              <span class="bg-purple-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">
                POPULAR
              </span>
              <div class="px-2 text-center h-32 lg:h-48 flex flex-col items-center justify-center">
                <h3 class="tracking-widest">PRO</h3>
                <h2 class="text-5xl text-gray-900 font-medium flex items-center justify-center leading-none mb-4 mt-2">
                  &#x20B9; 499
                  <span class="text-gray-600 text-base ml-1">/mo</span>
                </h2>
              </div>
              <p class="bg-gray-100 text-gray-600 h-16 lg:h-24 text-center px-2 flex items-center -mt-px justify-center border-t border-gray-400">
                25 MBPS
              </p>
              <p class="text-gray-600 text-center h-16 lg:h-24 flex items-center justify-center border-t border-gray-400">
                250 GB
              </p>
              <p class="bg-gray-100 text-gray-600 text-center h-24 flex items-center justify-center rounded border-t border-gray-400">
                Ideal for smart TV's, streaming and browsing
              </p>
            </div>
            <div class="lg:w-1/3 w-full lg:mt-px border-2 border-gray-400 lg:border-none shadow-md lg:shadow-none rounded-md lg:rounded-none">
              <div class="px-2 text-center h-32 lg:h-48 flex flex-col items-center justify-center">
                <h3 class="tracking-widest">BUSINESS</h3>
                <h2 class="text-5xl text-gray-900 font-medium flex items-center justify-center leading-none mb-4 mt-2">
                  &#x20B9; 699
                  <span class="text-gray-600 text-base ml-1">/mo</span>
                </h2>
              </div>
              <p class="bg-gray-100 text-gray-600 h-16 lg:h-24 text-center px-2 flex items-center -mt-px justify-center border-t border-gray-400">
                50 MBPS
              </p>
              <p class="text-gray-600 text-center h-16 lg:h-24 flex items-center justify-center border-t border-gray-400">
                500 GB
              </p>
              <p class="bg-gray-100 text-gray-600 text-center h-24 flex items-center justify-center rounded border-t border-gray-400">
                Ideal for High Definition streaming
              </p>
            </div>
          </div>
          <div className="text-center w-full p-4 italic text-sm lg:text-lg text-purple-500">
            <p>
              FUP applicable for all plans. Conditions apply
              <span className="text-red-700 font-bold ml-1">*</span>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Pricing;
