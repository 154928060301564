import React from "react";
import { useLocation } from "react-router-dom";

import Header from "../components/Header";
import Hero from "../components/Hero";
import About from "../components/About";
import Pricing from "../components/Pricing";
import Reviews from "../components/Reviews";
import Features from "../components/Features";
import Partners from "../components/Partners";
import HeroPattern from "../assets/bubbles.svg";

const LandingPage = () => {
  const location = useLocation();
  const path = location.pathname;
  let isHome = false;
  if (path !== "/pricing") {
    isHome = true;
  }
  return (
    <>
      <div style={{ backgroundImage: `url(${HeroPattern})` }}>
        <Header isHome={isHome} />
        <Hero />
      </div>
      <Features />
      <About />
      <Partners />
      <Pricing />
      <Reviews />
    </>
  );
};

export default LandingPage;
