import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import "./assets/main.css";

import Contact from "./components/Contact";
import Footer from "./components/Footer";

import LandingPage from "./layouts/LandingPage";
import PlansPage from "./layouts/PlansPage";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <div className="text-lg text-indigo-900 antialiased">
          <div className="relative bg-white">
            <Switch>
              <Route path="/" component={LandingPage} exact />
              <Route path="/pricing" component={PlansPage} exact />
            </Switch>
            <Contact />
            <Footer />
          </div>
        </div>
      </BrowserRouter>
    </>
  );
};

export default App;
