import React from "react";
import { useLocation } from "react-router-dom";

import Header from "../components/Header";
import HeroPattern from "../assets/bubbles.svg";
import Plans from "../components/Plans";

const PlansPage = () => {
  const location = useLocation();
  const path = location.pathname;

  let isHome = false;
  if (path !== "/pricing") {
    isHome = true;
  }
  return (
    <>
      <div style={{ backgroundImage: isHome ? `url(${HeroPattern})` : `` }}>
        <Header isHome={isHome} />
        <Plans />
      </div>
    </>
  );
};

export default PlansPage;
