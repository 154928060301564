import React from "react";
import { NavLink } from "react-router-dom";

//import Logo from "../assets/wifi-signal.svg";
//import JNBLogo from "../assets/images/jnetworkslogo.Jpeg";
import JnetLogo from "../assets/images/jnet_logo.svg";

const Header = (props) => {
  return (
    <div className="font-sans">
      <header className="text-gray-700 body-font">
        <div className="container mx-auto flex flex-wrap p-4 flex-col md:flex-row items-center">
          <a
            href="/"
            className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"
          >
            <img src={JnetLogo} alt="logo" className="h-24 w-64" />
          </a>
          <nav className="md:ml-auto flex flex-wrap mt-4 md:mt-0 items-center text-base justify-center">
            {props.isHome ? (
              <NavLink
                className="mr-4 shadow-lg bg-white transition duration-300 ease-in-out focus:outline-none focus:shadow-outline border border-purple-700 hover:bg-purple-700 text-purple-700 hover:text-white font-semibold py-2 px-4 rounded uppercase"
                to="/pricing"
              >
                Pricing
              </NavLink>
            ) : (
              <NavLink
                className="mr-4 shadow-lg bg-white transition duration-300 ease-in-out focus:outline-none focus:shadow-outline border border-purple-700 hover:bg-purple-700 text-purple-700 hover:text-white font-semibold py-2 px-4 rounded uppercase"
                to="/"
              >
                Home
              </NavLink>
            )}

            <a
              className="mr-4 shadow-lg bg-white transition duration-300 ease-in-out focus:outline-none focus:shadow-outline border border-purple-700 hover:bg-purple-700 text-purple-700 hover:text-white font-semibold py-2 px-4 rounded uppercase"
              // href="http://103.89.53.242/iconradius/user/"
              href="https://user.jnetworksbroadband.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              User Portal
            </a>
          </nav>
        </div>
      </header>

      {/* <header className="px-6 md:px-16 py-6 mt-0 md:mt-1 flex flex-wrap items-center">
        <div className="flex-1 flex justify-center items-center">
          <a
            href="/"
            className="flex title-font font-medium items-center text-gray-900 md:mb-0"
          >
            <img src={Logo} alt="logo" className="h-6 w-6" />
            <span className="ml-2 md:ml-3 text-xl md:text-3xl">
              <span className="text-green-900 font-extrabold mr-2">J</span>
              <span className="text-black mr-1">NETWORKS</span>
              <span className="text-red-700">BROADBAND</span>
            </span>
          </a>
        </div>
         <div className="block lg:hidden">
            <button
                onClick={() => toggleExpansion(!isExpanded)}
                className="flex items-center px-3 py-2 border rounded text-gray-200 border-gray-400 hover:text-white hover:border-white"
            >
                {!isExpanded ? (
                <svg
                    className="fill-current text-gray-900 h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                >
                    <title>Menu</title>
                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                </svg>
                ) : (
                <svg
                    className="fill-current text-gray-900 h-3 w-3"
                    viewBox="0 0 365.71733 365"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g fill="fill-current">
                    <path d="m356.339844 296.347656-286.613282-286.613281c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503906-12.5 32.769532 0 45.25l286.613281 286.613282c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082032c12.523438-12.480468 12.523438-32.75.019532-45.25zm0 0" />
                    <path d="m295.988281 9.734375-286.613281 286.613281c-12.5 12.5-12.5 32.769532 0 45.25l15.082031 15.082032c12.503907 12.5 32.769531 12.5 45.25 0l286.632813-286.59375c12.503906-12.5 12.503906-32.765626 0-45.246094l-15.082032-15.082032c-12.5-12.523437-32.765624-12.523437-45.269531-.023437zm0 0" />
                    </g>
                    <title>Menu</title>
                </svg>
                )}
            </button>
            </div>
            <div
            className={`${
                isExpanded ? `block` : `hidden`
            } w-full block justify-end flex-grow lg:flex lg:items-center lg:w-auto`}
            >
            <Menu />
            </div> 
      </header> */}
    </div>
  );
};

export default Header;
