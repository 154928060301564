import React from "react";

import N from "../assets/images/N.png";
import S from "../assets/images/S.jpg";
import A from "../assets/images/A.png";

const Reviews = () => {
  return (
    <section class="text-gray-700 pb-10  lg:pb-32 bg-purple-100 body-font">
      <div class="container px-5 py-20 mx-auto">
        <div class="flex flex-col text-center w-full mb-20">
          <h1 class="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900">
            Customer Reviews
          </h1>
        </div>
        <div class="flex flex-wrap m-4 md:-m-4">
          <div class="lg:w-1/3 lg:mb-0 mb-6 p-4 shadow-lg rounded-lg lg:border-2 bg-white lg:border-gray-200">
            <div class="h-full text-center">
              <img
                alt="testimonial"
                class="w-20 h-20 mb-8 p-2 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100"
                src={S}
              />
              <p class="leading-relaxed">
                I was facing a slow internet speed issue at night. I raised a
                ticket and the issue got resolved within few minutes. 👍
              </p>
              <span class="inline-block h-1 w-10 rounded bg-purple-500 mt-6 mb-4"></span>
              <h2 class="text-gray-900 font-medium title-font tracking-wider text-sm">
                Dr. Sandhya
              </h2>
              <p class="text-gray-500">Doctor</p>
            </div>
          </div>
          <div class="lg:w-1/3 lg:mb-0 mb-6 p-4 shadow-lg rounded-lg lg:border-2 bg-white lg:border-gray-200">
            <div class="h-full text-center">
              <img
                alt="testimonial"
                class="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100"
                src={A}
              />
              <p class="leading-relaxed">
                Great Speed and reliable connection. Using this connection for
                the past year and this has been a reliable connection
              </p>
              <span class="inline-block h-1 w-10 rounded bg-purple-500 mt-6 mb-4"></span>
              <h2 class="text-gray-900 font-medium title-font tracking-wider text-sm">
                Abhilash
              </h2>
              <p class="text-gray-500">Software Develeoper</p>
            </div>
          </div>
          <div class="lg:w-1/3 lg:mb-0 p-4 shadow-lg rounded-lg lg:border-2 bg-white lg:border-gray-200">
            <div class="h-full text-center">
              <img
                alt="testimonial"
                class="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100"
                src={N}
              />
              <p class="leading-relaxed">
                Very good service offered by Mr. Jabbar and his team. We are
                happy to use their services so far. One of the best ISP's in
                Sangareddy.
              </p>
              <span class="inline-block h-1 w-10 rounded bg-purple-500 mt-6 mb-4"></span>
              <h2 class="text-gray-900 font-medium title-font tracking-wider text-sm">
                Nirmala M
              </h2>
              <p class="text-gray-500">Home maker</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reviews;
