import React from "react";

//import Logo from "../assets/wifi-signal.svg";
//import JNBLogo from "../assets/images/jnetworkslogo.Jpeg";
import JnetLogo from "../assets/images/jnet_logo.svg";

import Love from "../assets/favourite.svg";
import YouTube from "../assets/youtube.svg";

const Footer = () => {
  return (
    <footer className="text-gray-700 bg-purple-100 body-font">
      <div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
        <a
          className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900"
          href="/"
        >
          <img src={JnetLogo} alt="logo" className="h-20 w-32" />
          {/* <span className="ml-2 md:ml-3 text-sm">
            <span className="text-green-900 font-extrabold mr-2">J</span>
            <span className="text-black mr-1">NETWORKS</span>
            <span className="text-red-700">BROADBAND</span>
          </span> */}
        </a>
        <p className="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">
          © 2020 jnetworksbroadband
        </p>

        <span className="inline-flex md:ml-4 sm:mt-0 mt-4 items-center md:justify-start justify-center">
          <a
            className="text-blue-800"
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.facebook.com/J-Networks-Broadband-Service-Sangareddy-1183461658378385/"
          >
            <svg
              fill="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              className="w-5 h-5"
              viewBox="0 0 24 24"
            >
              <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
            </svg>
          </a>
          <a
            className="ml-3 text-blue-800"
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.youtube.com/watch?v=qY4orO9fGv0"
          >
            <img
              src={YouTube}
              alt="youtube"
              className="inline-block h-4 w-4 mr-1"
            />
          </a>
        </span>
        <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
          <p className="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">
            Made with{" "}
            <img src={Love} alt="love" className="inline-block h-4 w-4 mr-1 " />
            by
            <a
              className="ml-1 text-purple-500"
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.linkedin.com/in/abhilashmandula/"
            >
              Abhilash Mandula
            </a>
          </p>
        </span>
      </div>
    </footer>
  );
};

export default Footer;
