import React from "react";

const About = () => {
  return (
    <>
      <section className="text-gray-700 bg-purple-200 body-font">
        <div className="container px-5 pb-20 mx-auto flex flex-col">
          <div className="lg:w-5/6 mx-auto">
            <div className="flex flex-col sm:flex-row">
              <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8 items-center my-auto justify-center">
                <div className="flex flex-col">
                  <h2 className="font-medium title-font my-4 text-purple-900 text-2xl md:text-3xl leading-relaxed tracking-wide uppercase">
                    Connecting to the world with advanced fiber optic technology
                  </h2>
                </div>
              </div>
              <div className="text-purple-900 sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-600 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                <p className="mb-6 lg:mb-12">
                  <span className="font-semibold">J Networks Broadband</span> is
                  the Internet Service Provider in Sangareddy since 2010 with
                  competitive service and plans.
                </p>
                <p className="mb-4">
                  We provide internet service to homes and offices both in rural
                  & urban areas with various plans. We are the largest internet
                  service providers in and around Sangareddy.
                </p>
                <br />
                <p className="mb-4">
                  J Networks Broadband is promoted by{" "}
                  <span className="font-bold text-purple-900">
                    JABBAR COMMUNICATIONS
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
