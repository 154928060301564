import React from "react";

import Technology from "../assets/technology.svg";
import Connectivity from "../assets/network.svg";
import Service from "../assets/money.svg";
import HiwTopWave from "../assets/hiw-wave.svg";
import HiwBottomWave from "../assets/hiw-bottom-wave.svg";
import HeroPattern from "../assets/bubbles.svg";

const Features = () => {
  return (
    <>
      <div
        className="-mb-4 sm:-mb-12 md:-mb-20 lg:-mb-12 xl:-mb-20"
        style={{ backgroundImage: `url(${HeroPattern})`, marginTop: -10 }}
      >
        <img src={HiwTopWave} alt="Hiw top wave" />
      </div>
      <section
        className="text-gray-700 bg-purple-100 body-font"
        style={{
          backgroundColor: "#d9afd9",
          backgroundImage: "linear-gradient(0deg, #d9afd9 0%, #97d9e1 100%)",
        }}
      >
        <div className="container px-5 py-24 mx-auto">
          {/* <div className="text-center mb-20">
            <h1 className="font-headline text-white uppercase text-xl md:text-2xl font-bold mb-4 md:mb-8">
              Connecting to the world with advanced fiber optic technology
            </h1>
            <div className="bg-indigo-500 w-1/5 h-1 mx-auto my-10 rounded-full"></div>
            <div className="text-lg md:text-xl lg:max-w-4xl mx-auto leading-normal text-gray-900">
              <p className="mb-6 lg:mb-12">
                <span className="font-semibold">J Networks Broadband</span> is
                the Internet Service Provider in Sangareddy since 2010 with
                competitive service and plans.
              </p>
              <p className="mb-4">
                We provide internet service to homes and offices both in rural &
                urban areas with various plans. We are the largest internet
                service providers in and around Sangareddy.
              </p>
              <br />
              <p className="mb-4">
                J Networks Broadband is promoted by{" "}
                <br className="hidden lg:inline-block"></br>
                <span className="font-semibold text-black">
                  JABBAR COMMUNICATIONS
                </span>
              </p>
            </div>
            <div className="flex mt-6 justify-center">
              <div className="w-16 h-1 rounded-full bg-indigo-500 inline-flex"></div>
            </div>
          </div> */}
          <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
            <div className="p-4 md:w-1/3 md:mb-0 mb-6 flex flex-col text-center items-center">
              <div className="w-32 h-32 bg-white inline-flex items-center justify-center rounded-full bg-transparent text-indigo-500 mb-5 flex-shrink-0">
                <img src={Technology} alt="technology" className="h-20 w-20" />
              </div>
              <div className="flex-grow">
                <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                  Technology
                </h2>
                <p className="leading-relaxed text-base text-gray-800 font-normal">
                  We provide advanced fiber optic technology with high quality
                  equipments and well trained fiber optic technicians
                </p>
              </div>
            </div>
            <div className="p-4 md:w-1/3 md:mb-0 mb-6 flex flex-col text-center items-center">
              <div className="w-32 h-32 bg-white inline-flex items-center justify-center rounded-full text-indigo-500 mb-5 flex-shrink-0">
                <img
                  src={Connectivity}
                  alt="connectivity"
                  className="h-20 w-20"
                />
              </div>
              <div className="flex-grow">
                <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                  Connectivity
                </h2>
                <p className="leading-relaxed text-base text-gray-800 font-normal">
                  We provide network connectivity for FE (Fast Ethernet) and GE
                  (Gigabyte Ethernet) with high quality Cat5e and Cat6 cables
                  for internet connectivity
                </p>
              </div>
            </div>
            <div className="p-4 md:w-1/3 md:mb-0 mb-6 flex flex-col text-center items-center">
              <div className="w-32 h-32 bg-white inline-flex items-center justify-center rounded-full text-indigo-500 mb-5 flex-shrink-0">
                <img src={Service} alt="service" className="h-20 w-20" />
              </div>
              <div className="flex-grow">
                <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                  Service
                </h2>
                <p className="leading-relaxed text-base text-gray-800 font-normal">
                  We partenered with A class internet service providers to
                  provide you the best service.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="xl:-mb-40 bg-purple-200">
        <img src={HiwBottomWave} alt="HIW bottom wave" />
      </div>
    </>
  );
};

export default Features;
